import React, { useState} from "react";
import AuthModal from "./AuthModal";
import RegisterModal from "./RegisterModal";
import RecoverModal from "./RecoverModal";

function AuthPage(){
    const [authSteps, setAuthSteps] = useState('auth');
    return (
        <div className='flex justify-center items-center w-full min-h-screen h-screen'>
            {authSteps === 'auth' && <AuthModal setAuthSteps={setAuthSteps}/>}
            {authSteps === 'register' && <RegisterModal setAuthSteps={setAuthSteps}/>}
            {authSteps === 'recover' && <RecoverModal setAuthSteps={setAuthSteps}/>}
        </div>
    );
}

export default AuthPage;