import React from 'react';
import {FacebookIcon, InstagramIcon, WhatsappIcon} from "../../helpers/Common";

function Contacts() {

    return (
        <div className='componentMainWrapper'>
            <div className='w-full pb-9'>
                <div className='flex w-full flex-row justify-start items-center gap-7 mb-6'>
                    <h1 className='page__title'>Контакты</h1>
                </div>
                <div className='w-full flex justify-between items-start p-9 gap-4 rounded-10px bg-white shadow-default'>
                    <div className='contacts__body'>
                        <div style={{gridColumnStart: 1, gridColumnEnd: 3}}>
                            <p>Наш адрес офиса и склада в Бишкеке:</p>
                            <span>г. Бишкек, ул. Чокана Валиханова 2/14Офис GOLDMARK (Складска зона АюГранд, склад К24)</span>
                        </div>
                        <div>
                            <p>График работы:</p>
                            <span>Пн - Пт <br/> с 10:00 - 18:00 </span>
                            <span>Сб <br/> с 11:00 – 17:00  </span>
                            <span>Вс <br/> выходной  </span>
                        </div>
                        <div>
                            <p>Контакты:</p>
                            <span><a href='tel:+996 551 600077'>+996 551 600077</a></span>
                            <span><a href='tel:+996 221 600077'>+996 221 600077</a></span>
                            <span><a href="mailto:goldmark.company@mail.ru">goldmark.company@mail.ru</a></span>
                        </div>
                        <div style={{gridColumnStart: 1, gridColumnEnd: 3}}>
                            <p>Соц сети:</p>
                            <div className='w-full flex gap-4'>
                                <a href=""><InstagramIcon/></a>
                                <a href=""><FacebookIcon/></a>
                                <a href=""><WhatsappIcon/></a>
                            </div>
                        </div>
                    </div>
                    <div className='w-full rounded-10px' style={{height:'470px'}}>
                        <a className='flex h-full w-full' style={{height:'100%', width: '100%'}} href="https://go.2gis.com/95ikc"></a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contacts;

