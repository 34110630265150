const defaultState = {
    user:{
        code: '',
        address: '',
        date_of_birth: '',
        first_name: 'User',
        last_name: 'User',
        email: '',
        phone: '',
        passport_front_side: '',
        passport_back_side: '',
        is_verified: false,
        balance: '',
    }
}

export const customerReducer = (state = defaultState, action: any) =>{
    switch (action.type){
        case "UPDATE_DATA":
            return {
                ...state, user:{...state.user, ...action.payload}
            }
        default: return state
    }
}