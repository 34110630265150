import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {
    CustomInput,
    PasswordHiddenIcon,
    PasswordVisibleIcon,
    PhotoAddIcon,
    RollBackIcon
} from "../../../helpers/Common";
import {MyTimer} from "../../../helpers/helpers";
import {GetOtp, PostUserRegisterData} from "../../../requests/post";
import mobileLogo from "../../../assets/images/Artboard 7 1.svg";

const RegisterModalM = ({setAuthSteps}: {setAuthSteps: Dispatch<SetStateAction<string>>}) => {
    const [registerSteps, setRegisterSteps] = useState('1')
    const [registerValidation, setRegisterValidation] = useState<any>({
        first_nameCheck: false,
        first_nameHelperText: '',
        last_nameCheck: false,
        last_nameHelperText: '',
        date_of_birthCheck:false,
        date_of_birthHelperText:'',
        emailCheck: false,
        emailHelperText: '',
        passwordCheck: false,
        passwordHelperText: '',
        phoneCheck: false,
        phoneHelperText: '',
        frontSideCheck: false,
        frontSideHelperText: '',
        backSideCheck: false,
        backSideHelperText: '',
        passwordVisible: false,
        otpCheck: false,
        otpHelperText: '',
        sendCode: true,
        registerRequestSent: false,
    })
    const [registerValues, setRegisterValues] = useState<any>({
        phone: '',
        email: '',
        password: '',
        repeat_password: '',
        otp: '',
        first_name: '',
        last_name: '',
        date_of_birth: '',
        frontSide: new File([""], ""),
        backSide: new File([""], ""),
    })
    const handleSubmit = (event: React.FormEvent) =>{
        event.preventDefault()
        if(registerSteps === '1'){
            setRegisterSteps('2')
        }
        if(registerSteps === '2'){
            setRegisterSteps('3')
        }
        if(registerSteps === '3'){
            setRegisterValidation({
                ...registerValidation,
                registerRequestSent: true,
            });
            PostUserRegisterData(registerValues.first_name,
                registerValues.last_name,
                registerValues.phone,
                registerValues.email,
                registerValues.password,
                registerValues.repeat_password,
                registerValues.otp,
                registerValues.frontSide,
                registerValues.backSide,
                registerValues.date_of_birth,
            ).then(()=>{
                window.location.reload()
            }).catch((err)=>{
                if(err.response.data.email){
                    setRegisterValidation({
                        ...registerValidation,
                        emailCheck: true,
                        emailHelperText: err.response.data.email[0],
                        registerRequestSent: false,
                    });
                }
                if(err.response.data.phone){
                    setRegisterValidation({
                        ...registerValidation,
                        phoneCheck: true,
                        phoneHelperText: err.response.data.phone[0],
                        registerRequestSent: false,
                    });
                }
                if(err.response.data.otp){
                    setRegisterValidation({
                        ...registerValidation,
                        otpCheck: true,
                        otpHelperText: err.response.data.otp[0],
                        registerRequestSent: false,
                    });
                }
                if(err.response.data.password){
                    setRegisterValidation({
                        ...registerValidation,
                        passwordCheck: true,
                        passwordHelperText: err.response.data.password[0],
                        registerRequestSent: false,
                    });
                }
            })
        }
    }
    const time = new Date();
    const currentTimeLeftForCode = MyTimer(time)
    const handleResendCode = () =>{
        setRegisterValidation({
            ...registerValidation,
            sendCode: true,
            otpHelperText: 'Код отправлен вам на почту'
        })
        const time = new Date();
        time.setSeconds(time.getSeconds() + 59);
        currentTimeLeftForCode.restart(time)
        GetOtp(registerValues.email).then(()=>{
        }).catch(()=>{
            setRegisterValidation({
                ...registerValidation,
                sendCode: false,
                otpHelperText: 'Ошибка при отправке кода',
                otpCheck: true,
            })
        })
    }
    useEffect(()=>{
        if(currentTimeLeftForCode.seconds === 0){
            setRegisterValidation({...registerValidation, sendCode: false})
        }
    }, [currentTimeLeftForCode.seconds])
    return (
        <div className='authBackground flex justify-center items-center w-full h-full'>
            {registerSteps === '1' &&
                <form onSubmit={handleSubmit} className='h-full flex flex-col justify-start items-center py-60px px-4 bg-light-blue rounded-10px shadow-default w-full relative'>
                    <div className='roll-back_button' onClick={()=>{setAuthSteps('auth')}}>
                        <RollBackIcon/>
                    </div>
                    <div className='mb-10'>
                        <img src={mobileLogo} alt="logo"/>
                    </div>
                    <h1 className="auth__title">Регистрация</h1>
                    <CustomInput
                        className='text-input'
                        type='text'
                        placeholder='Имя'
                        required={true}
                        label=''
                        value={registerValues.first_name}
                        onChange={(event)=>{setRegisterValues({
                            ...registerValues,
                            first_name: event.target.value,
                        })}}
                        helperText={registerValidation.first_nameHelperText}
                        error={registerValidation.first_nameCheck}
                    />
                    <CustomInput
                        className='text-input'
                        type='text'
                        placeholder='Фамилия'
                        required={true}
                        label=''
                        value={registerValues.last_name}
                        onChange={(event)=>{setRegisterValues({
                            ...registerValues,
                            last_name: event.target.value,
                        })}}
                        helperText={registerValidation.last_nameHelperText}
                        error={registerValidation.last_nameCheck}
                    />
                    <CustomInput
                        className='text-input'
                        type='date'
                        placeholder='Дата рождения'
                        label=''
                        value={registerValues.date_of_birth}
                        helperText={registerValidation.date_of_birthHelperText}
                        required={false}
                        onChange={(event)=>{setRegisterValues({
                            ...registerValues,
                            date_of_birth: event.target.value,
                        })}}
                        error={registerValidation.date_of_birthCheck}
                    />
                    <button type='submit' className='submit-button_blue h-12 w-full mb-4 mt-4'>
                        Далее
                    </button>
                    <div className='w-full flex justify-center items-center'>
                        <span className='register__label text-gray mr-1.5'>У вас есть аккаунт?</span>
                        <span className='register__text text-dark-blue' onClick={()=>{setAuthSteps('auth')}}>Войти</span>
                    </div>
                </form>
            }
            {registerSteps === '2' &&
                <form onSubmit={handleSubmit} className='h-full flex flex-col justify-start items-center py-60px px-4 bg-light-blue rounded-10px shadow-default relative w-full'>
                    <div className='roll-back_button' onClick={()=>{setRegisterSteps('1')}}>
                        <RollBackIcon/>
                    </div>
                    <div className='mb-10'>
                        <img src={mobileLogo} alt="logo"/>
                    </div>
                    <h1 className="auth__title">Регистрация</h1>
                    <CustomInput
                        className='text-input'
                        type='text'
                        placeholder='Передняя сторона паспорта'
                        required={false}
                        readOnly={true}
                        label=''
                        value={registerValues.frontSide.name}
                        helperText={registerValidation.frontSideHelperText}
                        error={registerValidation.frontSideCheck}
                        inputProps={
                            <div className='text-input_icon_button'>
                                {registerValues.frontSide.name === ''
                                    ?<label className='flex justify-center items-center cursor-pointer w-full h-full'>
                                        <input type="file" accept="image/*"
                                               onChange={(event)=>{
                                                   let files: FileList | null = event.currentTarget.files;
                                                   setRegisterValues({
                                                       ...registerValues,
                                                       frontSide: files?.item(0),
                                                   })
                                               }}/>
                                        <PhotoAddIcon/>
                                    </label>
                                    :<div className='flex justify-center items-center cursor-pointer w-full h-full rotate-45'
                                          onClick={()=>{
                                              setRegisterValues({
                                                  ...registerValues,
                                                  frontSide: new File([""], ""),
                                              })
                                          }}
                                    >
                                        <PhotoAddIcon/>
                                    </div>
                                }
                            </div>
                        }
                    />
                    <CustomInput
                        className='text-input'
                        type='text'
                        placeholder='Задняя сторона паспорта'
                        required={false}
                        readOnly={true}
                        label=''
                        value={registerValues.backSide.name}
                        helperText={registerValidation.backSideHelperText}
                        error={registerValidation.backSideCheck}
                        inputProps={
                            <div className='text-input_icon_button'>
                                {registerValues.backSide.name === ''
                                    ?<label className='flex justify-center items-center cursor-pointer w-full h-full'>
                                        <input type="file" accept="image/*"
                                               onChange={(event)=>{
                                                   let files: FileList | null = event.currentTarget.files;
                                                   setRegisterValues({
                                                       ...registerValues,
                                                       backSide: files?.item(0),
                                                   })
                                               }}/>
                                        <PhotoAddIcon/>
                                    </label>
                                    :<div className='flex justify-center items-center cursor-pointer w-full h-full rotate-45'
                                          onClick={()=>{
                                              setRegisterValues({
                                                  ...registerValues,
                                                  backSide: new File([""], ""),
                                              })
                                          }}
                                    >
                                        <PhotoAddIcon/>
                                    </div>
                                }
                            </div>
                        }
                    />
                    <div className='w-full flex justify-end items-center'>
                        <span className='forgot-password__text mb-2 mt-4 text-gray' onClick={()=>{setRegisterSteps('3')}}>Пропустить этот этап</span>
                    </div>
                    <button type='submit' className='submit-button_blue h-12 w-full mb-4 mt-4'>
                        Далее
                    </button>
                    <div className='w-full flex justify-center items-center'>
                        <span className='register__label text-gray mr-1.5'>У вас есть аккаунт?</span>
                        <span className='register__text text-dark-blue' onClick={()=>{setAuthSteps('auth')}}>Войти</span>
                    </div>
                </form>
            }
            {registerSteps === '3' &&
                <form onSubmit={handleSubmit} className='h-full flex flex-col justify-start items-center py-60px px-4 bg-light-blue rounded-10px shadow-default relative w-full'>
                    <div className='roll-back_button' onClick={()=>{setRegisterSteps('2')}}>
                        <RollBackIcon/>
                    </div>
                    <div className='mb-10'>
                        <img src={mobileLogo} alt="logo"/>
                    </div>
                    <h1 className="auth__title">Регистрация</h1>
                    <CustomInput
                        className='text-input'
                        type='text'
                        placeholder='Номер телефона'
                        required={true}
                        label=''
                        value={registerValues.phone}
                        onChange={(event)=>{setRegisterValues({
                            ...registerValues,
                            phone: '+' + event.target.value.replace(/\D/g, ''),
                        })}}
                        helperText={registerValidation.phoneHelperText}
                        error={registerValidation.phoneCheck}
                    />
                    <CustomInput
                        className='text-input'
                        type='email'
                        placeholder='Email'
                        required={true}
                        label=''
                        value={registerValues.email}
                        onChange={(event)=>{setRegisterValues({
                            ...registerValues,
                            email: event.target.value,
                        })}}
                        helperText={registerValidation.emailHelperText}
                        error={registerValidation.emailCheck}
                    />
                    <CustomInput
                        className='text-input otp-input'
                        type='text'
                        placeholder='Код'
                        required={true}
                        label=''
                        value={registerValues.otp}
                        onChange={(event)=>{setRegisterValues({
                            ...registerValues,
                            otp: event.target.value,
                        })}}
                        helperText={registerValidation.otpHelperText}
                        error={registerValidation.otpCheck}
                        inputProps={
                            <div className='otp_icon_button'>
                                {!registerValidation.sendCode
                                    ? <p onClick={()=>handleResendCode()}>Отправить код</p>
                                    : <p>0:{currentTimeLeftForCode.seconds}</p>
                                }
                            </div>
                        }
                    />
                    <CustomInput
                        className='text-input'
                        type={registerValidation.passwordVisible ? 'text' : 'password'}
                        placeholder='Пароль'
                        label=''
                        value={registerValues.password}
                        helperText={registerValidation.passwordHelperText}
                        required={true}
                        onChange={(event)=>{setRegisterValues({
                            ...registerValues,
                            password: event.target.value,
                        })}}
                        error={registerValidation.passwordCheck}
                        inputProps={
                            <div className='text-input_icon_button' onClick={()=>{
                                setRegisterValidation({
                                    ...registerValidation,
                                    passwordVisible: !registerValidation.passwordVisible,
                                })
                            }}>
                                {registerValidation.passwordVisible ? <PasswordHiddenIcon/> : <PasswordVisibleIcon/>}
                            </div>
                        }
                    />
                    <CustomInput
                        className='text-input'
                        type={registerValidation.passwordVisible ? 'text' : 'password'}
                        placeholder='Подтвердите пароль'
                        label=''
                        value={registerValues.repeat_password}
                        helperText={registerValues.password !== registerValues.repeat_password ? 'Пароли не совпадают!' : ''}
                        required={true}
                        onChange={(event)=>{setRegisterValues({
                            ...registerValues,
                            repeat_password: event.target.value,
                        })}}
                        error={registerValues.password !== registerValues.repeat_password}
                        inputProps={
                            <div className='text-input_icon_button' onClick={()=>{
                                setRegisterValidation({
                                    ...registerValidation,
                                    passwordVisible: !registerValidation.passwordVisible,
                                })
                            }}>
                                {registerValidation.passwordVisible ? <PasswordHiddenIcon/> : <PasswordVisibleIcon/>}
                            </div>
                        }
                    />
                    <button type='submit' disabled={registerValidation.registerRequestSent} className='submit-button_blue h-12 w-full mb-4 mt-4'>
                        {registerValidation.authRequestIsSent
                            ?<div className="loader"></div>
                            :'Зарегистрироваться'
                        }
                    </button>
                    <div className='w-full flex justify-center items-center'>
                        <span className='register__label text-gray mr-1.5'>У вас есть аккаунт?</span>
                        <span className='register__text text-dark-blue' onClick={()=>{setAuthSteps('auth')}}>Войти</span>
                    </div>
                </form>
            }
        </div>
    );
}

export default RegisterModalM;
