import React from 'react';
import {Link, useNavigate} from "react-router-dom";
import {GetCurrencies, GetOrderStatusButtons} from "../../requests/get";
import ShowAllStatusIcon from '../../assets/images/box-seam.svg'

function HomeM() {
    const navigate = useNavigate()
    const getOrderStatuses = GetOrderStatusButtons()
    const currencies = GetCurrencies()
    return (
        <div className='componentMainWrapper'>
            <div className='w-full pb-9'>

                <div className='w-full flex justify-between items-center gap-3 my-5'>
                    <div className='header-mainBar__exchange-text w-full'>
                        <p>Курс доллара</p>
                        <span>
                            {currencies.loading
                                ? '0'
                                : currencies.error
                                    ? '0'
                                    : currencies.result?.data.filter((currency: any) => currency.code === 'USD')[0].value
                            } $
                        </span>
                    </div>
                    <div className='header-mainBar__exchange-text w-full'>
                        <p>Курс юань</p>
                        <span>
                            {currencies.loading
                                ? '0'
                                : currencies.error
                                    ? '0'
                                    : currencies.result?.data.filter((currency: any) => currency.code === 'CNY')[0].value
                            } ¥
                        </span>
                    </div>
                </div>
                <div className='flex w-full flex-row justify-start items-center gap-7 mb-3'>
                    <h1 className='page__title'>Статусы заказов</h1>
                </div>

                {/* статусы */}

                <div className='home-page__statuses mb-7'>
                    <div className='status-button'
                         onClick={()=>{navigate(`/orderList/showAll`)}}>
                        <div className='w-12 h-12 flex justify-center items-center'>
                            <img src={ShowAllStatusIcon} alt="icon"/>
                        </div>
                        <div>
                            <p>Все посылки</p>
                            <span>
                                {!getOrderStatuses.loading && !getOrderStatuses.error && getOrderStatuses.result?.data.map((result: any)=>result.count_of_orders).reduce((partialSum: any, a: any) => partialSum + a, 0)}
                            </span>
                        </div>
                    </div>
                    {getOrderStatuses.loading
                        ? <div className='p-4'>
                            <div className="loader"></div>
                        </div>
                        : getOrderStatuses.error
                            ? <div>Error</div>
                            : getOrderStatuses.result?.data.map((item:any) => (
                                <div className='status-button'
                                     id={item.id}
                                     key={item.id}
                                     onClick={()=>{navigate(`/orderList/${item.status}`)}}>
                                    <div className='w-12 h-12 flex justify-center items-center'>
                                        <img src={item.icon} alt=""/>
                                    </div>
                                    <div>
                                        <p>{item.name}</p>
                                        <span>{item.count_of_orders}</span>
                                    </div>
                                </div>
                            ))
                    }
                </div>
                <div className='flex w-full flex-row justify-start items-center gap-7 mb-9'>
                    <Link to='/orderAdd' className='submit-button_blue w-full h-12'>
                        Добавить посылку
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default HomeM;

