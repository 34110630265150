import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {DeleteSelectedNotifications} from "../../requests/post";
import {CustomPagination, CustomTable, OrderDeleteIcon, OrderEditIcon} from "../../helpers/Common";
import {DeleteNotification, DeleteOrder} from "../../requests/delete";
import {GetNotifications} from "../../requests/get";
import moment from "moment/moment";
import parse from 'html-react-parser'
import {Pagination} from "@mui/material";

function NotificationsM() {
    const navigate = useNavigate()
    const [filterValue, setFilterValue] = React.useState<any>({
        page: 1,
        count: 1,
        update_request: false,
    })


    const notifications = GetNotifications(filterValue)

    useEffect(()=>{
        if(!notifications.loading && !notifications.error){
            setFilterValue((prevState: any)=>({
                ...prevState,
                count: notifications.result?.data.total_pages,
                page: notifications.result?.data.current_page,
            }))
        }
    },[notifications.loading])
    return (
        <div className='componentMainWrapper'>
            <div className='w-full pb-9'>
                <div className='flex w-full flex-row justify-start items-center gap-7 mb-6'>
                    <h1 className='page__title'>Уведомления </h1>
                </div>
                {notifications.loading
                    ? <div className='w-full flex justify-center items-center'>
                        <div className="loader"></div>
                    </div>
                    : !notifications.error && notifications.result?.data.results.length > 0
                        ? <div className='w-full flex flex-col justify-start items-center gap-5'>
                            {notifications.result?.data.results.map((notification: any, index: number)=>(
                                <div key={index} className='mobileOrderItem flex justify-between items-center w-full bg-white rounded-10px shadow-default py-5 px-3'
                                     onClick={()=>{navigate(`/notificationView/${notification.id}`)}}
                                >
                                    <div className="notifications__row w-full overflow-x-hidden flex flex-col justify-center items-start">
                                        <div className='flex justify-start items-center gap-3.5' style={{width: '100%', overflow:"hidden"}}>
                                            <p style={notification.read ? {color:'rgba(87,88,90,0.8)'} : {color: '#000032'}}>{notification.notification.title}</p>{parse(notification.notification.message)}
                                        </div>
                                        <div className='flex justify-start items-center gap-3.5'>
                                            <span>{notification.notification.created_time} </span><span>{!notification.read && '• Новое уведомление'}</span>
                                        </div>
                                    </div>
                                    <div className='page-table__icon-button' onClick={(event)=>{
                                        event.preventDefault()
                                        event.stopPropagation()
                                        DeleteNotification(notification.id).then(()=>{
                                            setFilterValue({
                                                ...filterValue,
                                                update_request:!filterValue.update_request
                                            })
                                        })
                                    }}>
                                        <OrderDeleteIcon/>
                                    </div>
                                </div>
                            ))}
                        </div>
                        : <div className='noDataText w-full flex justify-center items-center'><span>Ничего не найдено...</span></div>

                }
                <div className='flex w-full justify-center items-center mt-10'>

                    <Pagination
                        color="primary"
                        count={filterValue.count}
                        page={filterValue.page}
                        onChange={(event,value)=>{
                            setFilterValue({
                                ...filterValue,
                                page: value,
                            })
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default NotificationsM;

