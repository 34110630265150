import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/desktopVersion/Home";
import Layout from "./components/desktopVersion/Layout";
import AuthPage from "./pages/desktopVersion/auth/Auth";
import {IfAuthed, RequireAuth} from "./helpers/helpers";
import { useMediaQuery } from 'usehooks-ts'
import OrderAdd from "./pages/desktopVersion/OrderAdd";
import OrderEdit from "./pages/desktopVersion/OrderEdit";
import OrderView from "./pages/desktopVersion/OrderView";
import Notifications from "./pages/desktopVersion/Notifications";
import NotificationView from "./pages/desktopVersion/NotificationView";
import Addresses from "./pages/desktopVersion/Addresses";
import Contacts from "./pages/desktopVersion/Contacts";
import Personal from "./pages/desktopVersion/Personal";
import PersonalEdit from "./pages/desktopVersion/PersonalEdit";
import AuthPageM from "./pages/mobileVersion/authM/AuthM";
import HomeM from "./pages/mobileVersion/HomeM";
import LayoutM from "./components/mobileVersion/LayoutM";
import OrderAddM from "./pages/mobileVersion/OrderAddM";
import OrderListM from "./pages/mobileVersion/OrderListM";
import OrderViewM from "./pages/mobileVersion/OrderViewM";
import OrderEditM from "./pages/mobileVersion/OrderEditM";
import AddressesM from "./pages/mobileVersion/AddressesM";
import ContactsM from "./pages/mobileVersion/ContactsM";
import NotificationsM from "./pages/mobileVersion/NotificationsM";
import NotificationViewM from "./pages/mobileVersion/NotificationViewM";
import PersonalM from "./pages/mobileVersion/PersonalM";
import PersonalEditM from "./pages/mobileVersion/PersonalEditM";


function App() {
  const isMobileDevice = useMediaQuery("(max-width: 720px)");
  const isDesktopDevice = useMediaQuery("(min-width: 721px)");
  return (
      <BrowserRouter>
          {isDesktopDevice &&
            <Routes>
              <Route path='/*' element={
                <RequireAuth>
                  <Layout/>
                </RequireAuth>
              }>
                  <Route index element={<Home />}/>
                  <Route path='orderAdd' element={<OrderAdd />}/>
                  <Route path='orderEdit/:id' element={<OrderEdit />}/>
                  <Route path='orderView/:id' element={<OrderView />}/>
                  <Route path='notifications' element={<Notifications />}/>
                  <Route path='notificationView/:id' element={<NotificationView />}/>
                  <Route path='addresses' element={<Addresses />}/>
                  <Route path='contacts' element={<Contacts />}/>
                  <Route path='personal' element={<Personal />}/>
                  <Route path='personalEdit' element={<PersonalEdit />}/>
              </Route>
              <Route path='auth' element={
                <IfAuthed>
                  <AuthPage/>
                </IfAuthed>
              }/>
            </Routes>
          }
          {isMobileDevice &&
              <Routes>
                  <Route path='/*' element={
                      <RequireAuth>
                          <LayoutM/>
                      </RequireAuth>
                  }>
                      <Route index element={<HomeM />}/>
                      <Route path='orderAdd' element={<OrderAddM />}/>
                      <Route path='orderEdit/:id' element={<OrderEditM />}/>
                      <Route path='orderView/:id' element={<OrderViewM />}/>
                      <Route path='orderList/:status' element={<OrderListM/>} />
                      <Route path='addresses' element={<AddressesM />}/>
                      <Route path='contacts' element={<ContactsM />}/>
                      <Route path='notifications' element={<NotificationsM />}/>
                      <Route path='notificationView/:id' element={<NotificationViewM />}/>
                      <Route path='personal' element={<PersonalM />}/>
                      <Route path='personalEdit' element={<PersonalEditM />}/>
                  </Route>
                  <Route path='auth' element={
                      <IfAuthed>
                          <AuthPageM/>
                      </IfAuthed>
                  }/>
              </Routes>
          }

      </BrowserRouter>
  );
}

export default App;
