import React, { useState} from "react";
import AuthModalM from "./AuthModalM";
import RegisterModalM from "./RegisterModalM";
import RecoverModalM from "./RecoverModalM";

function AuthPageM(){
    const [authSteps, setAuthSteps] = useState('auth');
    return (
        <div className='flex justify-center items-center w-full min-h-screen h-screen'>
            {authSteps === 'auth' && <AuthModalM setAuthSteps={setAuthSteps}/>}
            {authSteps === 'register' && <RegisterModalM setAuthSteps={setAuthSteps}/>}
            {authSteps === 'recover' && <RecoverModalM setAuthSteps={setAuthSteps}/>}
        </div>
    );
}

export default AuthPageM;