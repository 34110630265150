import React, {Dispatch, SetStateAction, useState} from 'react';
import {PasswordHiddenIcon, PasswordVisibleIcon, CustomInput} from "../../../helpers/Common";
import {PostUserAuthData} from "../../../requests/post";
import {setCookie} from "typescript-cookie";
import bgImg from "../../../assets/images/image 53.svg"
import jwtDecode from "jwt-decode";

const AuthModal = ({setAuthSteps}: { setAuthSteps: Dispatch<SetStateAction<string>> }) => {
    const [authValidation, setAuthValidation] = useState<any>({
        emailCheck: false,
        emailHelperText: '',
        passwordCheck: false,
        passwordHelperText: '',
        passwordVisible: false,
        authRequestIsSent: false,
    })
    const [authValues, setAuthValues] = useState<any>({
        email: '',
        password: '',
    })
    const handleSubmit = (event: React.FormEvent) =>{
        event.preventDefault()
        setAuthValidation({
            ...authValidation,
            authRequestIsSent: true,
        })
        PostUserAuthData(authValues.email, authValues.password).then((res)=>{
            // Get the current time in seconds
            const currentTimeInSeconds = Math.floor(Date.now() / 1000);

            const accessDecode: any = jwtDecode(res.data.access)
            const refreshDecode: any = jwtDecode(res.data.refresh)

            const accessExpirationInSeconds = accessDecode.exp;
            const refreshExpirationInSeconds = refreshDecode.exp;

            // Calculate the difference in seconds
            const accessDifferenceInSeconds = accessExpirationInSeconds - currentTimeInSeconds;
            const refreshDifferenceInSeconds = refreshExpirationInSeconds - currentTimeInSeconds;

            // Convert the difference in seconds to days
            const accessDifferenceInDays = Math.ceil(accessDifferenceInSeconds / (60 * 60 * 24));
            const refreshDifferenceInDays = Math.ceil(refreshDifferenceInSeconds / (60 * 60 * 24));
            setCookie('goldMark_user_access_token', res.data.access, {expires: accessDifferenceInDays})
            setCookie('goldMark_user_refresh_token', res.data.refresh, {expires: refreshDifferenceInDays})
            setTimeout(()=>{
                window.location.reload();
            }, 1000)
        }).catch((err)=>{
            if(err.response.status === 401){
                setAuthValidation({
                    ...authValidation,
                    emailCheck: true,
                    emailHelperText: err.response.data.detail,
                    passwordCheck: true,
                    passwordHelperText: err.response.data.detail,
                    authRequestIsSent: false,
                });
            }
            if(err.response.status === 404){
                setAuthValidation({
                    ...authValidation,
                    emailCheck: true,
                    emailHelperText: err.response.statusText,
                    passwordCheck: true,
                    passwordHelperText: err.response.statusText,
                    authRequestIsSent: false,
                });
            }
        })
    }
    return (
        <div className='authBackground flex justify-end pr-20 items-center w-full h-full'>
            <form onSubmit={handleSubmit} className='z-20 flex flex-col justify-start items-center py-60px px-50px bg-light-blue rounded-10px shadow-default max-w-auth-width w-full'>
                <h1 className="auth__title">Авторизация</h1>
                <CustomInput
                    className='text-input'
                    type='email'
                    placeholder='Email'
                    required={true}
                    label=''
                    value={authValues.email}
                    onChange={(event)=>{setAuthValues({
                        ...authValues,
                        email: event.target.value,
                    })}}
                    helperText={authValidation.emailHelperText}
                    error={authValidation.emailCheck}
                />
                <CustomInput
                    className='text-input text-input_with-props'
                    type={authValidation.passwordVisible ? 'text' : 'password'}
                    placeholder='Пароль'
                    label=''
                    value={authValues.password}
                    helperText={authValidation.passwordHelperText}
                    required={true}
                    onChange={(event)=>{setAuthValues({
                        ...authValues,
                        password: event.target.value,
                    })}}
                    error={authValidation.passwordCheck}
                    inputProps={
                        <div className='text-input_icon_button' onClick={()=>{
                            setAuthValidation({
                                ...authValidation,
                                passwordVisible: !authValidation.passwordVisible,
                            })
                        }}>
                            {authValidation.passwordVisible ? <PasswordHiddenIcon/> : <PasswordVisibleIcon/>}
                        </div>
                    }
                />
                <div className='w-full flex justify-end items-center'>
                    <span className='forgot-password__text mb-7 text-gray' onClick={()=>{setAuthSteps('recover')}}>Забыли пароль?</span>
                </div>
                <button disabled={authValidation.authRequestIsSent} type='submit' className='submit-button_blue h-12 w-full mb-4'>
                    {authValidation.authRequestIsSent
                        ?<div className="loader"></div>
                        :'Войти'

                    }
                </button>
                <div className='w-full flex justify-center items-center'>
                    <span className='register__label text-gray mr-1.5'>У вас нет аккаунта?</span>
                    <span className='register__text text-dark-blue' onClick={()=>{setAuthSteps('register')}}>Зарегистрируйтесь</span>
                </div>
            </form>
        </div>
    );
}

export default AuthModal;

