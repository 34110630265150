import React, {useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {GetOrders, GetOrderStatusButtons} from "../../requests/get";
import {CustomInput, CustomPagination, FilterSearchIcon, OrderDeleteIcon, OrderEditIcon} from "../../helpers/Common";
import moment from "moment/moment";
import {DeleteOrder} from "../../requests/delete";
import {Pagination} from "@mui/material";

function OrderListM() {
    const navigate = useNavigate()
    const {status} = useParams()
    const [filterValue, setFilterValue] = React.useState<any>({
        start_created_at: '',
        end_created_at: '',
        search: '',
        page: 1,
        count: 1,
        status__status: status === 'showAll' ? '' : status,
        update_request: false,
    })
    const getOrderStatuses = GetOrderStatusButtons()
    const getOrders = GetOrders(filterValue)
    useEffect(()=>{
        if(!getOrders.loading && !getOrders.error){
            setFilterValue((prevState: any)=>({
                ...prevState,
                count: getOrders.result?.data.total_pages,
                page: getOrders.result?.data.current_page,
            }))
        }
    },[getOrders.loading])
    return (
        <div className='componentMainWrapper'>
            <div className='w-full pb-9'>
                <div className='flex w-full flex-row justify-start items-center gap-7 mb-3'>
                    <h1 className='page__title'>
                        {!getOrderStatuses.loading && !getOrderStatuses.error && status !== 'showAll'
                            ?
                            getOrderStatuses.result?.data.find((order_status: any)=>(
                                order_status.status === status
                            )).name
                            : 'Вся посылка'
                        }
                    </h1>
                </div>
                <div className='flex w-full flex-row justify-start items-center mb-3'>
                    <CustomInput
                        className='filter-input_search'
                        type='text'
                        placeholder='Введите номер трека или посылки'
                        value={filterValue.search}
                        onChange={(event)=>{setFilterValue({
                            ...filterValue,
                            search: event.target.value,
                            page: 1,
                        })}}
                        inputProps={
                            <div className='text-input_icon_button'>
                                <FilterSearchIcon/>
                            </div>
                        }
                    />
                </div>
                {getOrders.loading
                    ? <div className='w-full flex justify-center items-center'>
                        <div className="loader"></div>
                    </div>
                    : !getOrders.error && getOrders.result?.data.results.length > 0
                        ? <div className='w-full flex flex-col justify-start items-center gap-5'>
                            {getOrders.result?.data.results.map((order: any, index: number)=>(
                            <div key={index} className='mobileOrderItem w-full bg-white rounded-10px shadow-default py-5 px-3'
                                 onClick={()=>{navigate(`/orderView/${order.id}`)}}
                            >
                                <div className='mobileOrderItem__header w-full flex justify-between items-center mb-2'>
                                    <div className='flex gap-4'>
                                        <p>Посылка: {order.id}</p>
                                        <p>{moment(order.created_at).format('DD.MM.YYYY').toString()}</p>
                                    </div>
                                    <div className="flex justify-start items-center gap-4">
                                        {order.status.status === 'inProcess' &&
                                            <div className='page-table__icon-button' onClick={(event)=>{
                                                event.preventDefault()
                                                event.stopPropagation()
                                                navigate(`/orderEdit/${order.id}`)
                                            }}>
                                                <OrderEditIcon/>
                                            </div>
                                        }
                                        {order.status.status === 'inProcess' &&
                                            <div className='page-table__icon-button' onClick={(event)=>{
                                                event.preventDefault()
                                                event.stopPropagation()
                                                DeleteOrder(order.id).then(()=>{
                                                    setFilterValue({
                                                        ...filterValue,
                                                        update_request:!filterValue.update_request
                                                    })
                                                })
                                            }}>
                                                <OrderDeleteIcon/>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className='mobileOrderItem__body w-full flex flex-col justify-start items-start mb-2'>
                                    <div>
                                        <span>Наименование</span>
                                        <p>{order.title}</p>
                                    </div>
                                    <div>
                                        <span>Трек номер</span>
                                        <p>{order.tracking_number}</p>
                                    </div>
                                </div>
                                <div className='mobileOrderItem__footer w-full flex justify-between items-start'>
                                    <div>
                                        <span>Кол-во</span>
                                        <p>{order.quantity}</p>
                                    </div>
                                    {/*<div>*/}
                                    {/*    <span>Вес (кг)</span>*/}
                                    {/*    <p>{order.weight}</p>*/}
                                    {/*</div>*/}
                                    <div>
                                        <span>Статус</span>
                                        <img style={{width: '18px', height: '18px'}} src={order.status.icon} alt="icon"/>
                                    </div>
                                </div>
                            </div>
                        ))}
                        </div>
                        : <div className='noDataText w-full flex justify-center items-center'><span>Ничего не найдено...</span></div>

                }
                <div className='flex w-full justify-center items-center mt-10'>
                    <Pagination
                        color="primary"
                        count={filterValue.count}
                        page={filterValue.page}
                        onChange={(event,value)=>{
                            setFilterValue({
                                ...filterValue,
                                page: value,
                            })
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default OrderListM;

